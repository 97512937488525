@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountRmaStatusList {
    --button-padding: 0;
    --button-height: auto;

    &-SubHeading {
        font-size: 13px;
        line-height: 19px;
    }

    &-Heading {
        display: flex;
        font-size: 30px;
        line-height: 42px;
        margin-block: 0 36px;

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
        }

        @include tabletAndAbove {
            align-items: center;
        }

        .MyAccountRmaStatusList-Status {
            padding-block: 8px;

            @include mobile {
                margin-block-start: 8px;
            }

            @include tabletAndAbove {
                margin-inline-start: 24px;
            }
        }
    }

    &-Status {
        padding-inline: 16px;
        padding-block: 4px;
        background-color: $color-neutral-20;
        color: $color-neutral-70;
        font-size: 12px;
        line-height: 18px;

        &_status {
            &_rejected {
                background-color: $color-secondary-75;
                color: $white;
            }

            &_pending {
                background-color: $color-other-3;
                color: $white;
            }

            &_resolved {
                background-color: $color-other-4;
                color: $white;
            }
        }
    }

    .Table-Cell {
        &.MyAccountRmaStatusList-Cell {
            &_id,
            &_order_id {
                width: 180px;
                min-width: 180px;
            }

            &_action {
                text-align: right;
            }
        }
    }
}
