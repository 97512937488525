@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccount {
    &-Wrapper {
        padding-block-end: 56px;

        @include tabletAndAbove {
            padding-block-end: 64px;
        }

        @include desktopAndAbove {
            padding-block-end: 100px;
        }
    }

    &-SubHeading {
        color: $color-neutral-60;
        font-size: 24px;
    }

    &-TabContent {
        overflow: visible;
    }
}
