@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountDashboard {
    grid-gap: 30px;
    margin-bottom: 64px;

    .CmsBlock {
        &-Wrapper {
            &_type_my-account-dashboard, p {
                font-size: 16px;
                grid-column: 1 / -1;
            }
        }
    }
}
