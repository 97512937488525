@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountMyOrders {
    &-Table {
        width: auto;
    }

    th {
        font-size: 16px;
        padding: 16px 32px;
    }

    th.hidden-mobile {
        width: 50px;
    }

    th.link-header {
        width: 50px;
        min-width: 50px;
        padding: 0;
        text-align: right;
    }
}

@include mobile {
    .MyAccountMyOrders {
        position: relative;
        overflow-x: scroll;
    }
}
