@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountNewsletterSubscription {
    &-Button {
        margin-top: 32px;
    }

    &-Header {
        color: $black;
        font-size: 16px;
        line-height: 24px;
        margin: 5px 0 32px 0;
    }
}

@include mobileAndTablet {
    .MyAccountNewsletterSubscription {
        padding: 16px;
        display: block;

        &-Header {
            font-size: 14px;
            line-height: 20px;
            margin: 0;
            padding: 0 0 20px 0;
        }
    }
}
