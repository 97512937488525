@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.LoyaltyProgram {
    &-Points {
        color: $black;
        font-size: 16px;
        margin: 0 0 16px 0;

        span {
            font-weight: 500;
        }
    }

    &-Header {
        color: rgba(0, 0, 0, 0.64);
        font-size: 14px;
        margin: 0 0 32px 0;
    }

    &-SubHeader {
        color: $black;
        font-size: 16px;
        line-height: 24px;
    }

    &-Button {
        margin: 32px 0 0 0;
    }
}
