@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountOrderPopup {
    &-Image {
        width: 110px;
        height: 82px;
    }

    &-Name {
        max-width: 284px;
        font-size: 13px;
        line-height: 19.5px;
    }

    &-Price {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        margin-right: 9px;
        margin-top: 5px;
    }

    &-Products {
        margin-top: 0;
        margin-bottom: 0;
    }

    &-Row {
        padding-top: 19px;
        padding-bottom: 24px;
    }

    &-OrderWrapper {
        margin-top: 32px;

        dl {
            grid-template-columns: repeat(2, 180px);
            margin-bottom: 0;
        }
    }

    &-Content {
        grid-gap: 0;
        padding-bottom: 20px;

        h4 {
            font-size: 16px;
            line-height: 22px;
        }

        dt {
            font-weight: 400;
            font-size: 14px;
        }

        dl {
            margin-top: 16px;
            grid-gap: 16px;
        }

        dd {
            color: $color-neutral-70;
        }

        .KeyValueTable {
            th.KeyValueTable-Heading {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                padding-inline: 24px;
                padding-block: 16px;
            }

            th, td {
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                padding-inline: 24px;
                padding-block: 16px;
            }

            td {
                color: $color-neutral-70;
            }
        }
    }

    .Popup {
        &-Content {
            &::after {
                display: none;
            }
        }

        &-Heading {
            margin-bottom: 32px;
        }
    }
}

@include mobile {
    .MyAccountOrderPopup {
        &-Image {
            width: 70px;
        }

        &-Row {
            grid-template-columns: 70px 1fr 96px;
        }

        &-Price {
            word-spacing: 0;
        }
    }
}
