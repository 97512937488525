@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    @include desktop {
        --my-account-tab-list-background: #{$white};
    }
}

.MyAccountTabList {
    border: var(--my-account-content-border);

    &-ExpandableContentButton {
        padding: 9px 23px 8px;

        &::before,
        &::after {
            inset-inline-end: 24px;
        }
    }

    &-ExpandableContentHeading {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
}

@include mobile {
    .MyAccountTabList {
        margin: 0;

        &-ExpandableContentContent {
            &_isContentExpanded {
                padding: 12px 23px;
                margin: 0;
            }
        }

        &-ExpandableContentButton {
            &::before,
            &::after {
                inset-inline-end: 28px;
            }
        }
    }
}

@include tablet {
    .MyAccountTabList {
        margin: 10px 0 32px;
    }
}

@include mobileAndTablet {
    .MyAccountTabList {
        &-ExpandableContentContent_isContentExpanded {
            border-top: 1px solid $color-neutral-30;
            padding: 12px 23px;
        }
    }
}

@include desktopAndAbove {
    .MyAccountTabList {
        background-color: var(--my-account-tab-list-background);
        min-width: 287px;

        &-ExpandableContentContent {
            margin-block-start: 0;
            padding: 12px 0;
        }

        &-ExpandableContentButton {
            display: none;
        }
    }
}
