@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountRmaOrderList {
    --button-padding: 0;
    --button-height: auto;

    &-Heading {
        font-size: 30px;
        line-height: 42px;
        margin-block: 0 36px;
    }

    &-SubHeading {
        color: $color-neutral-70;
        font-size: 13px;
        line-height: 19px;

        @include desktopAndAbove {
            margin-block: -21px 16px;
        }
    }

    .Table-Cell {
        min-width: 180px;
        width: 180px;

        &.MyAccountRmaOrderList-Cell_action {
            text-align: right;
        }
    }
}
