@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountTabListItem {
    &-Button {
        font-size: 16px;
        color: $dark-half-transparent;
        width: 100%;
    }

    &_isActive {
        button {
            &.MyAccountTabListItem {
                &-Button {
                    color: $black;
                    font-weight: 500;
                }
            }
        }
    }
}
