@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountSignIn {
    &-IsAccount {
        margin: 0 0 24px 0;
    }

    &-Link {
        margin-bottom: 32px;
    }

    .Field {
        &_type {
            &_email,
            &_password {
                margin-bottom: 16px;
            }
        }
    }
}
