@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountOrderTableRow {
    td {
        font-size: 16px;
        padding: 16px 32px;
    }

    td.MyAccountOrderTableRow-Link {
        width: auto;
        padding: 0;
        text-align: right;
        
        span {
            color: $color-primary-95;
            margin-right: 30px;
            cursor: pointer;
        }
    }

    &:hover {
        background-color: transparent;
        cursor: default;
    }
}
