@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

@include desktopAndAbove {
    .MyAccountAddressPopup {
        .FieldForm-Fields {
            max-width: 612px;
            margin-right: -24px;
        }

        .Field-Wrapper {
            &:not(.Field-Wrapper_type_checkbox) {
                width: 50%;
                max-width: 318px;
                float: left;
                padding-right: 24px;
            }
        }

        .FieldGroup-Wrapper + .FieldGroup-Wrapper {
            .Field-Wrapper {
                &:not(.Field-Wrapper_type_checkbox) {
                    &:nth-of-type(2n + 1) {
                        clear: none;
                    }
                }
            }
        }

        .FieldForm-Fields > .Field-Wrapper {
            &:last-child {
                clear: none;
            }
        }

        .FieldForm-Fields::after {
            clear: both;
            display: table;
            content: '';
        }
    }
}

.MyAccountAddressPopup {
    .MyAccountAddressForm {
        &-TelephoneInfo {
            display: none;
        }
    }

    .Field {
        &-CheckboxLabelContent {
            color: $color-neutral-70;
            font-size: 16px;
            font-weight: 600;
        }

        [type="checkbox"] {
            +.input-control {
                border-radius: 3px;
                --box-color: white;

                border: 1px solid $color-neutral-70;

                &_disabled {
                    border-color: $color-neutral-30  !important;
                    --box-color: #{$color-neutral-30} !important;

                    &::after {
                        --checkmark-color: #{$color-neutral-30} !important;

                        box-shadow: none;
                        background: url('../../assets/images/checked.png');
                        width: 12px;
                        height: 9px;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        position: absolute;
                    }
                }
            }

            &:checked {
                +.input-control {
                    --box-color: #{$color-primary-50};

                    border-color: $color-primary-50;

                    &::after {
                        --checkmark-color: #{$color-neutral-5};

                        box-shadow: none;
                        background: url('../../assets/images/checked.png');
                        width: 12px;
                        height: 9px;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        position: absolute;
                    }

                    &_disabled {
                        &::after {
                            --checkmark-color: #{$color-neutral-5} !important;
                        }
                    }

                    +span {
                        color: $color-primary-50;
                    }
                }

                ~ .Field-CheckboxLabelContent {
                    color: $color-primary-95;
                }
            }

            &:not(:checked) {
                &:hover {
                    +.input-control {
                        --box-color: #{$color-primary-95};

                        border-color: $color-primary-95;

                        &::after {
                            --checkmark-color: #{$white};

                            box-shadow: none;
                            background: url('../../assets/images/checked.png');
                            width: 12px;
                            height: 9px;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            position: absolute;
                        }
                    }
                }
            }
        }
    }

    .Button {
        width: 222px;
    }

    .Field_variant_primary.Field_hasError [type='text'],
    .Field_variant_primary.Field_hasError [type='tel'] {
        background: $color-secondary-5;
    }

    .FieldForm-Body input {
        height: 48px;
    }

    .Field-Label {
        padding-bottom: 0;
        height: 24px;
        margin: 0 0 8px 0;
    }
}
