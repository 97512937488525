@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountAddressForm {
    &-InvoiceInfo {
        color: $black;
    }

    &-AdditionalInfo {
        color: $color-neutral-75;
        font-size: 12px;
        letter-spacing: 0;
    }
}
